import styled from "@emotion/styled"

export const SearchBox = styled.input`
    font-size: 1rem;
    padding: 1rem;
    width: 250px;
    display: block;
    margin: 2rem auto;
    border: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
    color: ${({ theme }) => theme.colors.secondary};
    
    ::placeholder {
      color: ${({ theme }) => theme.colors.muted};
    }
`