import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Recaptcha from 'react-recaptcha';
// import Tilt from 'react-parallax-tilt';

import Layout from '../components/layout/layout';
import { SearchBox } from '../components/search-box/search-box';
import { Button } from '../components/button/button';
import Logo from '../components/logo/logo';

const LogoLink = styled(Link)`
  margin-top: 1rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1100px;
  margin: 2rem auto;
`

const ContentWrap = styled.div`
  display: flex;
  margin-top: 3rem;
  padding: 2rem;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    margin-top: 0;
  }
`

const FormContainer = styled.div`
  text-align: left;
  margin: 0 5rem 0 1rem;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 1rem;
  }
`

const Heading = styled.h1`
  margin: 0;
  text-align: left;

  @media screen and (max-width: 1100px) {
    text-align: center;
  }
`

const Text = styled.p`
  margin: 1rem 0;
  @media screen and (max-width: 1100px) {
    text-align: center;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 300px;

  @media screen and (max-width: 1100px) {
    align-items: center;
  }
`

const EmailInput = styled(SearchBox)`
  margin: 0 0 1rem;
  width: 89%;
`

const DisabledButton = styled(Button)`

   &:hover {
        box-shadow: ${({ disabled, theme }) => disabled===true ? `none` : `0px 0px 20px ${theme.colors.primary}`};
    }
`

const recaptchaStyle = css`
  margin: 0 0 1rem;
`
const RECAPTCHA_KEY = "6Le3GJkgAAAAAG-o8qWkeW9EbmMer8COF9nFjIq5";

const CheatSheetPage = () => {

  const [disabled, setDisabled] = React.useState(true);

  const verifyCallback = () => {
    setDisabled(false);
  };

  const imgStyle = {
    borderRadius: "1rem",
    // border: "1px solid lightslategrey",
    boxShadow: "0px 0px 20px lightslategray"
  }

  return (
    <Layout pageTitle="Cheatsheet" showNav={false}>
      <title>Calorie Planning Cheat Sheet</title>
      <Container>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <ContentWrap>
          <FormContainer>
            <Heading>Something is Cookin'</Heading>
            <Text>Over <strong>150</strong> foods to kick start your calorie planning goals.</Text>
            <Form method="post" action="https://app.convertkit.com/forms/3342785/subscriptions" data-sv-form="3342785" data-uid="f0d10937d6">
              <EmailInput type="email" name="email_address" aria-label="Email Address" placeholder="Email Address" required />
              <Recaptcha css={recaptchaStyle} sitekey={RECAPTCHA_KEY} verifyCallback={verifyCallback} />
              <DisabledButton primary disabled={disabled}>Download</DisabledButton>
            </Form>
          </FormContainer>
          {/* <Tilt> */}
            <StaticImage 
              src="../../static/img/cheat-sheet-thumb-zoom.jpg" 
              alt="thumbnail of good food cheat sheet" 
              style={imgStyle}
              imgStyle={imgStyle}
            />
          {/* </Tilt> */}
        </ContentWrap>
      </Container>
      <script src="https://f.convertkit.com/ckjs/ck.5.js" />
    </Layout>
  )
}

export default CheatSheetPage;
